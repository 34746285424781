<template>
  <div data-app>
    <template>
      <v-data-table
        :headers="headers"
        :items="listAdmins"
        class="elevation-1"
        :page.sync="page"
        @pagination="pagination"
        @update:items-per-page="updateItemPerPage"
        @update:page="updatePage"
      >
        <template v-slot:top>
          <v-toolbar flat color="white">
            <v-card-title>
              {{ lang.listAdmin }}
              <v-spacer></v-spacer>
            </v-card-title>
            <v-spacer></v-spacer>
          </v-toolbar>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-icon small class="mr-2" @click="editItem(item, item.id)">
            mdi-pencil
          </v-icon>
          <v-icon small @click="deleteItem(item, item.id)">
            mdi-delete
          </v-icon>
        </template>
        <template v-slot:no-results>
          <v-alert :value="true" color="error" icon="warning">
            Your search for "{{ search }}" found no results.
          </v-alert>
        </template>
      </v-data-table>
    </template>
    <updateUserInfo :dialog="dialog" :id="user_id"></updateUserInfo>
  </div>
</template>
<script>
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
import {
  DELETE_USER,
  GET_USER_INFO,
  GET_LIST_ADMIN,
  GET_ADMIN_SEARCH_RESULT
} from "@/store/user.module";
import { validationMixin } from "vuelidate";
import updateUserInfo from "@/components/user/updateUserInfo";

export default {
  mixins: [validationMixin],
  components: {
    updateUserInfo
  },
  data: () => ({
    lang: {
      listAdmins: "Danh sách Admin"
    },
    buttonText: "Tìm kiếm",
    buttonColor: "primary",
    searchOptions: [
      { text: "1", id: 1 },
      { text: "2", id: 0 }
    ],
    user_id: "",
    searchOption: 1,
    dialog: false,
    search: "Admin",
    page: 1,
    itemsPerPage: 0,
    key_search: "",
    headers: [
      {
        text: "#id",
        align: "start",
        sortable: false,
        value: "id"
      },
      { text: "Tên", value: "full_name" },
      { text: "Email", value: "email" },
      { text: "Số điện thoại", value: "phone_number" },
      { text: "Trình độ", value: "level" },
      { text: "Hành động", value: "actions", sortable: false }
    ],
    image: {},
    editedIndex: -1,
    editedItem: {
      id: "",
      title: "loremislum",
      content: "heyasfasfa",
      author: "jobert",
      created_date: "2020-09-08"
    },
    defaultItem: {
      id: "",
      title: "loremislum",
      content: "heyasfasfa",
      author: "jobert",
      created_date: "2020-09-08"
    },
    url: null
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New Item" : "Edit Item";
    },
    listAdmins() {
      return this.$store.getters.listAdmins;
    },
    total() {
      return this.$store.getters.studentCount;
    },
    submitTypeName() {
      return this.editedIndex === -1 ? "Save" : "Update";
    }
  },
  mounted() {
    this.getAdmins();
  },
  watch: {
    dialog(val) {
      val || this.close();
    }
  },

  created () {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Admin",titledad:'Người dùng' }]);
  },

  methods: {
    getAdmins() {
      var payload = { page: this.page, page_count: this.itemsPerPage };
      this.$store.dispatch(GET_LIST_ADMIN, payload);
    },

    pagination($event) {
      console.log($event);
    },

    updateItemPerPage($event) {
      this.itemsPerPage = $event;
      this.getAdmins();
    },

    updatePage($event) {
      this.page = $event;
      this.getAdmins();
    },

    async editItem(item, user_id) {
      await this.$store.dispatch(GET_USER_INFO, { id: user_id });
      this.dialog = !this.dialog;
      this.editedIndex = this.students.indexOf(item);
    },
    async deleteItem(item, user_id) {
      if (confirm("are you sure?")) {
        await this.$store.dispatch(DELETE_USER, { id: user_id });
        this.editedIndex = this.students.indexOf(item);
        this.students.splice(this.editedIndex, 1);
      }
    },
    getSearchResult() {
      var payload = { type: this.searchOption, key_search: this.key_search };
      this.$store.dispatch(GET_ADMIN_SEARCH_RESULT, payload);
    }
  }
};
</script>
<style>
#preview {
  display: flex;
  justify-content: center;
  align-items: center;
}

#preview img {
  max-width: 100%;
  max-height: 500px;
}
</style>
